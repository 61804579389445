import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  id: "Diversity",
  class: "flex w-full flex-col items-center justify-start"
}
const _hoisted_2 = { class: "w-full max-w-screen-2xl overflow-hidden text-slate-300" }
const _hoisted_3 = { class: "mx-[6%] space-y-4 text-center lg:text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_heading = _resolveComponent("sub-heading")!
  const _component_block = _resolveComponent("block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_sub_heading, {
        class: "px-[6%]",
        value: _ctx.title
      }, null, 8, ["value"]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blocks, (block, key) => {
          return (_openBlock(), _createBlock(_component_block, {
            key: key,
            value: block
          }, null, 8, ["value"]))
        }), 128))
      ])
    ])
  ]))
}