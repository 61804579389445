<template>
    <div id="Contact" class="flex w-full flex-col items-center justify-start">
        <div class="mb-20 w-full max-w-screen-2xl text-slate-300 lg:mx-12">
            <sub-heading class="px-[6%]" :value="title" />

            <div class="flex flex-wrap justify-evenly px-[2%]">
                <div class="my-12 min-w-[20rem] flex-[1_0_30%] px-[4%] sm:min-w-[30rem]">
                    <div class="whitespace-pre-line">{{ block }}</div>
                    <div class="mt-10 hidden space-x-1 xl:block" v-for="(contact, key) in contactTypes" :key="key">
                        <span>{{ contact.beforeLink }}</span>
                        <a class="text-amber-500" :href="contact.link" target="_blank">{{ contact.linkText }}</a>
                        <span>{{ contact.afterLink }}</span>
                    </div>
                </div>

                <div class="w-[60%] flex-[1_0_60%] px-[2%]">
                    <div class="flex w-full flex-col content-center items-start justify-center space-y-2 rounded-md bg-slate-800 p-2 text-slate-300 ring-1 ring-slate-700 ring-offset-slate-800">
                        <div class="text-md">Contact Form</div>
                        <div class="whitespace-pre text-sm text-rose-500">{{ validationError }}</div>
                        <div class="whitespace-pre text-sm text-lime-500">{{ messageSuccess }}</div>
                        <div v-if="messageSuccess === ''" class="flex w-full flex-col flex-wrap content-center space-y-2 text-sm md:flex-row md:content-start md:items-start md:justify-start md:space-x-2 md:space-y-0">
                            <div class="w-full md:flex-[1_0_40%]">
                                <textarea v-model="name" rows="1" :class="nameClass" class="duration-400 block w-full resize-none rounded-sm border-0 bg-slate-900 p-2 text-slate-300 placeholder-slate-500 caret-slate-300 outline-none ring-1 ring-slate-500 ring-offset-slate-800 transition focus:bg-slate-900" placeholder="Name" />
                            </div>
                            <div class="w-full md:flex-[1_0_50%]">
                                <textarea v-model="email" rows="1" :class="emailClass" class="duration-400 block w-full resize-none rounded-sm border-0 bg-slate-900 p-2 text-slate-300 placeholder-slate-500 caret-slate-300 outline-none ring-1 ring-slate-500 ring-offset-slate-800 transition focus:bg-slate-900" placeholder="Email"></textarea>
                            </div>
                        </div>
                        <div v-if="messageSuccess === ''" class="w-full text-sm">
                            <textarea v-model="linkedin" rows="1" :class="linkedinClass" class="duration-400 block w-full resize-none rounded-sm border-0 bg-slate-900 p-2 text-slate-300 placeholder-slate-500 caret-slate-300 outline-none ring-1 ring-slate-500 ring-offset-slate-800 transition focus:bg-slate-900" placeholder="Linkedin profile (optional)"></textarea>
                        </div>
                        <div v-if="messageSuccess === ''" class="w-full text-sm">
                            <textarea v-model="message" rows="7" :class="messageClass" class="duration-400 block min-h-10 w-full resize-y rounded-sm border-0 bg-slate-900 p-2 text-slate-300 placeholder-slate-500 caret-slate-300 outline-none ring-1 ring-slate-500 ring-offset-slate-800 transition focus:bg-slate-900" placeholder="Write your message here..."></textarea>
                        </div>
                        <div v-if="messageSuccess === ''" class="flex w-full items-center justify-between space-x-4 pt-2">
                            <!-- <label class="flex flex-[0_1_100%] items-center justify-start">
                                <span class="text-md material-symbols-outlined mr-2 flex items-center justify-center rounded-full p-1 font-light text-slate-100 hover:bg-amber-500">attach_file</span>
                                <input type="file" @change="attachmentUpdate" ref="attachmentInput" multiple accept="image/*,.pdf" class="w-full text-sm text-slate-500 file:hidden" />
                            </label> -->
                            <div class="flex items-center justify-center">
                                <input type="checkbox" v-model="acceptedPrivacy" :class="acceptedPrivacyClass" class="mr-2 h-4 w-4 rounded checked:accent-amber-500" />
                                <span class="mr-2 text-nowrap text-sm">Agree with <router-link class="text-amber-500" to="/privacy-policy">Privacy Policy</router-link> ?</span>
                            </div>
                            <div @click="sendEmail" class="text-md material-symbols-outlined flex items-center justify-center rounded-full p-1 font-light text-slate-100 hover:bg-amber-500">send</div>
                        </div>
                    </div>

                    <div class="mt-10 space-x-1 px-[4%] xl:hidden" v-for="(contact, key) in contactTypes" :key="key">
                        <span>{{ contact.beforeLink }}</span>
                        <a class="text-amber-500" :href="contact.link" target="_blank">{{ contact.linkText }}</a>
                        <span>{{ contact.afterLink }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { IContactTypes, IDBHandler } from '@/types';
import { Component, Inject, Prop, Vue } from 'vue-facing-decorator';
import SubHeading from './SubHeading.vue';

@Component({
    components: {
        SubHeading
    }
})
export default class ContactUsComponent extends Vue {
    @Inject
    public dbHandler: IDBHandler;

    @Prop
    public title: string;
    @Prop
    public block: string;
    @Prop
    public contactTypes: IContactTypes[];

    public validationError = '';
    public messageSuccess = '';

    public name = '';
    public nameClass = '';
    public email = '';
    public emailClass = '';
    public linkedin = '';
    public linkedinClass = '';
    public message = '';
    public messageClass = '';
    public acceptedPrivacy = false;
    public acceptedPrivacyClass = '';

    public get attachments(): FileList {
        const input = this.$refs.attachmentInput as HTMLInputElement;
        return input && input.files instanceof FileList ? input.files : new FileList();
    }

    public attachmentUpdate(): void {
        window.console.log((this.$refs.attachmentInput as HTMLInputElement).files);
    }

    public validationCheck(): boolean {
        let validated = true;
        this.validationError = '';
        this.nameClass = '';
        this.emailClass = '';
        this.messageClass = '';
        this.acceptedPrivacyClass = '';

        if (this.name === '') {
            this.validationError += 'Name cannot be empty\n';
            this.nameClass = '!ring-rose-500';
            validated = false;
        }
        if (this.email === '') {
            this.validationError += 'Email cannot be empty\n';
            this.emailClass = '!ring-rose-500';
            validated = false;
        } else if (!this.email.includes('@') || !this.email.includes('.')) {
            this.validationError += 'Email is not a valid email\n';
            this.emailClass = '!ring-rose-500';
            validated = false;
        }
        if (this.message === '') {
            this.validationError += 'Message cannot be empty\n';
            this.messageClass = '!ring-rose-500';
            validated = false;
        }
        if (!this.acceptedPrivacy) {
            this.validationError += 'You must agree to the privacy policy if you wish to submit this information\n';
            this.acceptedPrivacyClass = 'ring-1 !ring-rose-500';
            validated = false;
        }
        return validated;
    }

    public sendEmail(): void {
        if (!this.validationCheck()) {
            return;
        }
        this.dbHandler
            .sendEmail({
                name: this.name,
                email: this.email,
                linkedin: this.linkedin,
                message: this.message,
                acceptedPrivacy: this.acceptedPrivacy
                // attachements: this.attachments
            })
            .then(() => {
                this.validationError = '';
                this.name = '';
                this.email = '';
                this.message = '';
                this.acceptedPrivacy = false;
                this.linkedin = '';

                this.messageSuccess = 'Message sent successfully';
            })
            .catch(() => {
                this.validationError = 'A problem occured using the contact form';
            });
    }
}
</script>

<style></style>
