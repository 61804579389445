<template>
    <div id="Diversity" class="flex w-full flex-col items-center justify-start">
        <div class="w-full max-w-screen-2xl overflow-hidden text-slate-300">
            <sub-heading class="px-[6%]" :value="title" />
            <div class="mx-[6%] space-y-4 text-center lg:text-left">
                <block v-for="(block, key) in blocks" :key="key" :value="block" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import SubHeading from './SubHeading.vue';
import Block from './Block.vue';

@Component({
    components: {
        SubHeading,
        Block
    }
})
export default class DiversityComponent extends Vue {
    @Prop
    public title: string;

    @Prop
    public blocks: string[];
}
</script>

<style></style>
