<template>
    <div id="About" class="flex w-full flex-col items-center justify-start">
        <div class="w-full max-w-screen-2xl overflow-hidden text-slate-300 lg:mx-12">
            <sub-heading class="px-[6%]" :value="title" />

            <quote-component :name="founderName" :role="founderRole" :quote="founderQuote"></quote-component>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import QuoteComponent from './QuoteComponent.vue';
import SubHeading from './SubHeading.vue';

@Component({
    components: {
        QuoteComponent,
        SubHeading
    }
})
export default class AboutComponent extends Vue {
    @Prop
    public title: string;

    @Prop
    public slogan: string;

    @Prop
    public companyBlock: string;

    @Prop
    public founderName: string;

    @Prop
    public founderRole: string;

    @Prop
    public founderQuote: string;
}
</script>

<style></style>
