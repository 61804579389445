import { ITask } from './abstract-task';

export class Bootstrapper<T> {
    private tasksLists: ITask<T>[][] = [];

    public addTasks(...tasks: ITask<T>[] | ITask<T>[][]): Bootstrapper<T> {
        if (this.multipleLists(tasks)) {
            for (const taskList of tasks) {
                this.tasksLists.push(taskList);
            }
        } else {
            this.tasksLists.push(tasks);
        }
        return this;
    }

    public process(arg: T): Promise<void> {
        let promiseChain = Promise.resolve();
        for (const tasksList of this.tasksLists) {
            promiseChain = promiseChain.then(() => this.recursiveProcessTaskChunk(tasksList, arg));
        }
        return promiseChain;
    }

    private recursiveProcessTaskChunk(tasksList: ITask<T>[], arg: T): Promise<void> {
        const taskListOfPromises = [];
        for (const task of tasksList) {
            taskListOfPromises.push(task.process(arg));
        }
        return Promise.all(taskListOfPromises).then(() => void 0);
    }

    // if any of the items are arrays then we have submitted multiple lists
    private multipleLists<T>(tasks: ITask<T>[] | ITask<T>[][]): tasks is ITask<T>[][] {
        return Array.isArray(tasks[0]);
    }
}
