<template>
    <div id="Services" class="flex w-full flex-col items-center justify-start">
        <div class="w-full max-w-screen-2xl">
            <div class="flex flex-col justify-start text-slate-300">
                <sub-heading class="px-[6%]" :value="title" />
                <block class="mx-[6%] mt-2 text-center lg:text-left" :value="block" />
            </div>
        </div>
        <div class="w-full min-w-[22rem] px-[6%]">
            <div class="my-10 flex flex-wrap items-center justify-center transition">
                <div class="ring-offset-10 relative m-2 flex min-h-[33rem] flex-[0_1_32rem] flex-col items-center justify-start overflow-hidden bg-slate-900 px-4 py-12 shadow-xl ring-1 ring-slate-700 ring-offset-slate-800 transition hover:z-10 hover:scale-110 hover:contrast-[1.05]" v-for="(service, key) in services" :key="key">
                    <span v-if="service.symbol" class="material-symbols-outlined text-center text-8xl">{{ service.symbol }}</span>
                    <h4 class="mb-4 mt-8 text-center text-xl font-semibold text-indigo-500 md:text-left">{{ service.title }}</h4>
                    <block class="mx-4 mb-6 text-center" :value="service.block" size="md-o" />
                    <div class="text-md mx-auto w-4/5 divide-y divide-slate-100 text-center text-slate-300">
                        <div class="p-4" v-for="(point, key) in service.options" :key="key">{{ point }}</div>
                    </div>
                    <div class="absolute bottom-[5%] h-1 w-4/5 rounded-full bg-slate-700 shadow-md" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { IService } from '@/types';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import SubHeading from './SubHeading.vue';
import Block from './Block.vue';

@Component({
    components: {
        Block,
        SubHeading
    }
})
export default class ServicesComponent extends Vue {
    @Prop
    public title: string;

    @Prop
    public block: string;

    @Prop
    public services: IService[];
}
</script>

<style></style>
