<template>
    <div class="home flex flex-col items-center">
        <header-component :sub-title="slogan" :text-block="companyBlock" :button="headerButton" />
        <div class="w-full bg-slate-900/80 backdrop-blur-[3px]">
            <about-component :title="aboutTitle" :slogan="slogan" :company-block="companyBlock" :founder-name="founderName" :founder-role="founderRole" :founder-quote="founderQuote" />
            <div class="mx-auto mt-20 h-1 w-11/12 rounded-full bg-slate-700 shadow-md" />
            <services-component :title="servicesTitle" :block="servicesBlock" :services="services" />
        </div>

        <values-component :title="valuesTitle" :block="valuesBlock" :values="values" />

        <div class="w-full bg-slate-900/80 backdrop-blur-[3px]">
            <diversity-component :title="diversityTitle" :blocks="diversityBlocks" />
            <div class="mx-auto mt-20 h-1 w-11/12 rounded-full bg-slate-700 shadow-md" />
            <contact-us-component :title="contactUsTitle" :block="contactUsBlock" :contactTypes="contactUsTypes" />
        </div>
    </div>
</template>

<script lang="ts">
import { IButton, IContactTypes, IExternalConfig, IService, IValue } from '@/types';
import { Component, Inject, Vue } from 'vue-facing-decorator';
import AboutComponent from './components/AboutComponent.vue';
import HeaderComponent from './components/HeaderComponent.vue';
import ServicesComponent from './components/ServicesComponent.vue';
import ValuesComponent from './components/ValuesComponent.vue';
import DiversityComponent from './components/DiversityComponent.vue';
import ContactUsComponent from './components/ContactUsComponent.vue';

@Component({
    components: {
        HeaderComponent,
        AboutComponent,
        ServicesComponent,
        ValuesComponent,
        DiversityComponent,
        ContactUsComponent
    }
})
export default class HomeView extends Vue {
    @Inject()
    public externalConfig: IExternalConfig;

    public slogan: string;
    public companyBlock: string;
    public headerButton: IButton;

    public aboutTitle: string;
    public founderName: string;
    public founderRole: string;
    public founderQuote: string;

    public servicesTitle: string;
    public servicesBlock: string;
    public services: IService[];

    public valuesTitle: string;
    public valuesBlock: string;
    public values: IValue[];

    public diversityTitle: string;
    public diversityBlocks: string[];

    public contactUsTitle: string;
    public contactUsBlock: string;
    public contactUsTypes: IContactTypes[];

    public created(): void {
        this.slogan = this.externalConfig.slogan;
        this.companyBlock = this.externalConfig.companyBlock;
        this.headerButton = this.externalConfig.headerButton;
        this.aboutTitle = this.externalConfig.aboutTitle;
        this.founderQuote = this.externalConfig.founderQuote;
        this.founderName = this.externalConfig.founderName;
        this.founderRole = this.externalConfig.founderRole;
        this.servicesTitle = this.externalConfig.servicesTitle;
        this.servicesBlock = this.externalConfig.servicesBlock;
        this.services = this.externalConfig.services;
        this.valuesTitle = this.externalConfig.valuesTitle;
        this.valuesBlock = this.externalConfig.valuesBlock;
        this.values = this.externalConfig.values;
        this.diversityTitle = this.externalConfig.diversityTitle;
        this.diversityBlocks = this.externalConfig.diversityBlocks;
        this.contactUsTitle = this.externalConfig.contactUsTitle;
        this.contactUsBlock = this.externalConfig.contactUsBlock;
        this.contactUsTypes = this.externalConfig.contactUsTypes;
    }
}
</script>

<style></style>
