<template>
    <div :class="[childClass, sizes[size]]" class="text-slate-300">{{ value }}</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component
export default class Block extends Vue {
    public readonly sizes: { [key: string]: string } = {
        sm: 'text-sm md:text-md xl:text-lg',
        md: 'text-md md:text-lg',
        'md-o': 'text-md'
    };

    @Prop({ default: 'md' })
    public size: string;

    @Prop
    public value: string;

    @Prop({ default: '' })
    public childClass: string;
}
</script>

<style></style>
