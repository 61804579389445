<template>
    <div :class="[navigationMDAnimationClass, scrolledGreaterThanClass]" class="duration-400 relative z-10 flex w-full flex-col items-center justify-between text-nowrap bg-transparent drop-shadow-md transition md:fixed lg:flex-row">
        <div class="z-10 flex w-full justify-between bg-slate-900 px-8 py-6 md:w-auto md:justify-center md:bg-transparent">
            <div @click="toggleMenu" :class="navigationMDAnimationClass" class="animate-fade-down animate-once md:hidden">
                <span class="material-symbols-outlined align-center justify-self-center text-3xl">
                    {{ currentMenuIcon }}
                </span>
            </div>
            <div @click="goToHome" class="flex w-56 animate-fade-down flex-row-reverse items-center justify-evenly text-lg animate-once md:flex-row">
                <img src="@/assets/Logo.svg" class="h-8 w-12 object-contain" />
                <div>
                    <span class="font-bold">{{ splitTitle[0] }}</span> {{ splitTitle[1] }}
                </div>
            </div>
        </div>
        <nav :class="navigationAnimationClass" class="absolute z-0 mt-20 flex h-auto w-full flex-col items-center justify-end divide-y bg-slate-900/60 py-6 transition-transform animate-once md:relative md:mt-0 md:w-auto md:translate-y-0 md:animate-fade-down md:flex-row md:space-x-8 md:divide-none md:border-none md:bg-transparent md:from-transparent lg:pr-16">
            <router-link v-for="(page, key) in normalPages" :key="key" :class="[navItemClasses, navItemAnimationClass, { [activeClass]: isActive(page.path) }]" :to="page.path">{{ page.locale }}</router-link>
            <router-link :class="[navItemClasses, navItemAnimationClass, endNavItemClass, { [activeClass]: isActive(lastPage.path) }]" :to="lastPage.path">{{ lastPage.locale }}</router-link>
        </nav>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

export interface IPage {
    path: string;
    locale: string;
}

@Component
export default class Navigation extends Vue {
    public readonly navItemClasses = 'p-2 w-60 sm:w-50 md:w-auto text-center';
    public readonly endNavItemClass = 'md:ring-1 md:ring-indigo-500 md:rounded md:drop-shadow-md md:hover:bg-indigo-500 md:hover:text-indigo-50';
    public readonly navItemAnimationClass = 'transition-transform hover:-translate-y-0.5 hover:scale-110';
    public readonly activeClass = 'text-indigo-400 font-bold';

    public navigationAnimationClass = 'duration-400 -translate-y-[300%]';
    public navigationMDAnimationClass = 'translate-y-0';
    public scrolledGreaterThanClass = '';

    public showMenu = false;

    private prevScrollPos = window.screenY;

    @Prop()
    public title: string;

    @Prop()
    private pages: IPage[];

    @Prop()
    private scrollThreshold: number;

    public get normalPages(): IPage[] {
        return this.pages.slice(0, this.pages.length - 1);
    }

    public get lastPage(): IPage {
        return this.pages[this.pages.length - 1];
    }

    public get currentMenuIcon(): string {
        return this.showMenu ? 'menu_open' : 'menu';
    }

    public get splitTitle(): string[] {
        return this.title.split(' ');
    }

    public created(): void {
        window.addEventListener('scroll', () => this.onScroll());
    }

    public isActive(matchPath: string): boolean {
        const hash = this.$route.hash.split('?')[0];
        const path = this.$route.path;
        const mainPath = path + hash;

        return mainPath.toLocaleLowerCase() === matchPath.toLocaleLowerCase();
    }

    public goToHome(): void {
        this.$router.push('/');
    }

    public toggleMenu(): void {
        this.showMenu = !this.showMenu;
        if (this.showMenu) {
            this.navigationAnimationClass = 'translate-y-0';
        } else {
            this.navigationAnimationClass = '-translate-y-[300%]';
        }
    }

    private onScroll(): void {
        if (window.scrollY > this.scrollThreshold) {
            this.scrolledGreaterThanClass = 'md:bg-slate-800/60 backdrop-blur-sm';
            if (window.scrollY > this.prevScrollPos) {
                this.navigationMDAnimationClass = '-translate-y-full';
                if (this.showMenu) {
                    this.toggleMenu();
                }
            } else {
                this.navigationMDAnimationClass = 'translate-y-0';
            }
        } else {
            this.scrolledGreaterThanClass = '';
        }
        this.prevScrollPos = window.scrollY;
    }
}
</script>

<style></style>
