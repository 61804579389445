<template>
    <div class="3xl:justify-between mx-[6%] flex flex-col-reverse items-center justify-evenly lg:flex-row">
        <div class="mt-8 flex w-full flex-col flex-nowrap content-center items-center justify-center space-y-4 font-semibold md:w-auto md:flex-row md:space-x-4 md:space-y-0 lg:mt-4 lg:flex-col lg:space-x-0 lg:space-y-4 lg:text-center xl:flex-row xl:space-x-4 xl:space-y-0">
            <div class="founder-image size-24 rounded-full bg-cover lg:size-40" />
            <div class="text-center lg:ml-0">
                <div>{{ name }}</div>
                <div class="mt-0.5 text-sm leading-6 text-slate-400">{{ role }}</div>
            </div>
        </div>
        <div>
            <block class="mx-[1%] max-w-3xl text-justify lg:text-left" :value="quote" />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import Block from './Block.vue';

@Component({
    components: {
        Block
    }
})
export default class QuoteComponent extends Vue {
    @Prop
    public name: string;

    @Prop
    public role: string;

    @Prop
    public quote: string;
}
</script>

<style>
.founder-image {
    background-image: url('~@/assets/Founder 988x1136.png');
}
</style>
