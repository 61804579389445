import { IButton, IContactTypes, IExternalConfig, IService, IValue } from '@/types';
import { RemoteConfig, getValue } from 'firebase/remote-config';

enum CodingKeys {
    title = 'Title',
    navigationItems = 'NavigationItems',

    slogan = 'Slogan',
    companyBlock = 'companyBlock',
    headerButton = 'HeaderButton',

    aboutTitle = 'aboutTitle',
    founderQuote = 'founderQuote',
    founderName = 'founderName',
    founderRole = 'founderRole',

    servicesTitle = 'servicesTitle',
    servicesBlock = 'servicesBlock',
    services = 'services',

    valuesTitle = 'valuesTitle',
    valuesBlock = 'valuesBlock',
    values = 'values',

    diversityTitle = 'diversityTitle',
    diversityBlocks = 'diversityBlocks',

    contactUsTitle = 'contactUsTitle',
    contactUsBlock = 'contactUsBlock',
    contactUsTypes = 'contactUsTypes',

    maintenanceMode = 'maintenanceMode'
}

export class Config implements IExternalConfig {
    private remoteConfig: RemoteConfig;

    constructor(remoteConfig: RemoteConfig) {
        this.remoteConfig = remoteConfig;
    }

    public get title(): string {
        return getValue(this.remoteConfig, CodingKeys.title).asString();
    }
    public get navigationItems(): string {
        return getValue(this.remoteConfig, CodingKeys.navigationItems).asString();
    }
    public get slogan(): string {
        return getValue(this.remoteConfig, CodingKeys.slogan).asString();
    }
    public get companyBlock(): string {
        return getValue(this.remoteConfig, CodingKeys.companyBlock).asString();
    }
    public get headerButton(): IButton {
        return JSON.parse(
            getValue(this.remoteConfig, CodingKeys.headerButton).asString()
        ) as IButton;
    }
    public get aboutTitle(): string {
        return getValue(this.remoteConfig, CodingKeys.aboutTitle).asString();
    }
    public get founderQuote(): string {
        return getValue(this.remoteConfig, CodingKeys.founderQuote).asString();
    }
    public get founderName(): string {
        return getValue(this.remoteConfig, CodingKeys.founderName).asString();
    }
    public get founderRole(): string {
        return getValue(this.remoteConfig, CodingKeys.founderRole).asString();
    }
    public get servicesTitle(): string {
        return getValue(this.remoteConfig, CodingKeys.servicesTitle).asString();
    }
    public get servicesBlock(): string {
        return getValue(this.remoteConfig, CodingKeys.servicesBlock).asString();
    }
    public get services(): IService[] {
        return JSON.parse(
            getValue(this.remoteConfig, CodingKeys.services).asString()
        ) as IService[];
    }
    public get valuesTitle(): string {
        return getValue(this.remoteConfig, CodingKeys.valuesTitle).asString();
    }
    public get valuesBlock(): string {
        return getValue(this.remoteConfig, CodingKeys.valuesBlock).asString();
    }
    public get values(): IValue[] {
        return JSON.parse(getValue(this.remoteConfig, CodingKeys.values).asString()) as IValue[];
    }
    public get diversityTitle(): string {
        return getValue(this.remoteConfig, CodingKeys.diversityTitle).asString();
    }
    public get diversityBlocks(): string[] {
        return JSON.parse(
            getValue(this.remoteConfig, CodingKeys.diversityBlocks).asString()
        ) as string[];
    }
    public get contactUsTitle(): string {
        return getValue(this.remoteConfig, CodingKeys.contactUsTitle).asString();
    }
    public get contactUsBlock(): string {
        return getValue(this.remoteConfig, CodingKeys.contactUsBlock)
            .asString()
            .replaceAll('\\n', '\n');
    }
    public get contactUsTypes(): IContactTypes[] {
        return JSON.parse(
            getValue(this.remoteConfig, CodingKeys.contactUsTypes).asString()
        ) as IContactTypes[];
    }
    public get maintenanceMode(): boolean {
        if (window.location.href.includes('?bypass=true')) {
            return false;
        }
        return getValue(this.remoteConfig, CodingKeys.maintenanceMode).asBoolean();
    }
}
