<template>
    <div ref="background-div" class="fixed h-screen w-screen bg-gradient-to-b from-black to-indigo-900"></div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { AnimatedNodeLinkBackground } from './animated-node-link-background';

@Component
export default class HeaderBackground extends Vue {
    private background: AnimatedNodeLinkBackground;
    private lastResizeTimestamp = 0;
    private currentResizeTimeout = 0;

    public mounted(): void {
        const width = document.documentElement.clientWidth;
        const height = document.documentElement.clientHeight;
        this.background = new AnimatedNodeLinkBackground(this.$refs['background-div'] as Element, width, height);
        window.addEventListener('resize', () => this.onResize());
    }

    public beforeUnmount(): void {
        window.removeEventListener('resize', () => this.onResize());
        this.background?.destroy();
        delete (this as any).background;
    }

    private onResize(): void {
        const previousResizeTimestamp = this.lastResizeTimestamp;
        this.lastResizeTimestamp = Date.now();
        window.clearTimeout(this.currentResizeTimeout);

        // allow the first resize to go through but for performance reasons limit the next ones
        if (Date.now() - previousResizeTimestamp < 1000) {
            this.currentResizeTimeout = setTimeout(() => this.updateBackgroundSize(), 1000);
            return;
        }

        this.updateBackgroundSize();
    }

    private updateBackgroundSize(): void {
        const width = document.documentElement.clientWidth;
        const height = document.documentElement.clientHeight;
        this.background?.updateCanvasSize(width, height);
    }
}
</script>

<style></style>
