<template>
    <div class="maintenance">
        <background class="z-0" />
        <div class="absolute left-[10%] right-[10%] top-[40%] flex animate-fade-up flex-col items-center animate-once">
            <h1 class="p-4 text-center text-4xl font-bold md:text-6xl">Site under maintenance.</h1>
            <h3 class="p-4 text-center text-xl md:text-3xl">We should be back up and running soon</h3>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import Background from '@/components/background/Index.vue';

@Component({
    components: {
        Background
    }
})
export default class MaintenanceView extends Vue {}
</script>

<style></style>
