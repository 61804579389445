import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-screen w-screen" }
const _hoisted_2 = { class: "absolute top-[20%] mx-6 mt-20 flex animate-fade-up flex-col items-center animate-once md:left-[10%] md:right-[10%] md:top-[44%] md:m-0 md:h-[60%]" }
const _hoisted_3 = { class: "p-4 text-center text-4xl font-bold md:text-5xl" }
const _hoisted_4 = { class: "text-md p-4 text-center text-slate-300 md:text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.subTitle), 1),
      _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.textBlock), 1),
      _createVNode(_component_router_link, {
        class: "mx-4 w-auto rounded p-2 text-center text-slate-300 ring-1 ring-amber-500 drop-shadow-md hover:bg-amber-500 hover:text-indigo-50",
        to: _ctx.button.path
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.button.locale), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}