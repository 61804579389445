import { Node } from './node';
import { NodeLink } from './node-link';

export class AnimatedNodeLinkBackground {
    public static readonly targetFPS = 48;
    public static readonly targetDeltaTime = Math.floor(1000 / this.targetFPS);
    public static readonly performanceUPS = 8;
    public static calculateNodeAmount(width: number, height: number): number {
        const pixels = width * height;
        const ppi = 150 ** 2;
        return Math.min(60, Math.max(24, Math.floor(pixels / ppi)));
    }

    private canvas: HTMLCanvasElement;
    private context: CanvasRenderingContext2D;
    private lastFrameTime = Date.now();
    private lastLinkUpdateTime = Date.now();
    private ctor = AnimatedNodeLinkBackground;
    private speedMultiplier = 1;
    private previousScrollY = window.scrollY;
    private currentFrameRequest = 0;
    private strokeColour = 'white';
    private shadowColour = 'white';

    constructor(el: Element, width: number, height: number) {
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        const context = canvas.getContext('2d');

        if (!context) {
            throw new Error('Error: context not found canvas');
        }

        this.canvas = canvas;
        this.context = context;
        el.append(this.canvas);
        this.updateCanvasSize(width, height);

        for (let i = 0; i < this.ctor.calculateNodeAmount(width, height); i++) {
            const node = new Node(this.context, [
                Math.floor(Math.random() * width),
                Math.floor(Math.random() * height)
            ]);
            node.updateCanvasSize(width, height);
        }
        this.animate();
    }

    public get width(): number {
        return this.canvas.width;
    }
    public get height(): number {
        return this.canvas.height;
    }

    public updateCanvasSize(width: number, height: number) {
        this.canvas.width = width;
        this.canvas.height = height;
        this.context.strokeStyle = this.strokeColour;
        // Due to performance reasons we can't have this
        // this.context.shadowColor = this.shadowColour;

        for (const node of Node.nodes) {
            node.updateCanvasSize(width, height);
        }
    }
    public destroy(): void {
        cancelAnimationFrame(this.currentFrameRequest);
        for (const node of Node.nodes) {
            node.destroy();
        }
        Node.nodes.length = 0;
        this.canvas.remove();
        delete (this as any).canvas;
        delete (this as any).context;
    }

    private animate() {
        if (Date.now() - this.lastFrameTime < this.ctor.targetDeltaTime) {
            this.currentFrameRequest = requestAnimationFrame(() => this.animate());
            return;
        }
        this.lastFrameTime = Date.now();

        this.context.clearRect(0, 0, this.width, this.height);

        this.updateSpeedMultiplier();
        if (Date.now() - this.lastLinkUpdateTime > 1000 / this.ctor.performanceUPS) {
            this.updateNodeLinks(this.width, this.height);
            this.cleanDirtyNodes();
            this.lastLinkUpdateTime = Date.now();
        }
        this.moveNodes();
        this.drawNodes();
        this.currentFrameRequest = requestAnimationFrame(() => this.animate());
    }

    private updateSpeedMultiplier(): void {
        const scrollDiffPercent = ((window.scrollY - this.previousScrollY) / this.height) * 100;
        this.previousScrollY = window.scrollY;
        this.speedMultiplier = scrollDiffPercent >= 0 ? 1 + scrollDiffPercent : scrollDiffPercent;
    }

    private cleanDirtyNodes(): void {
        for (const node of Node.nodes) {
            if (node.dirty) {
                node.clean();
            }
            node.checkBounds();
        }
    }

    private updateNodeLinks(width: number, height: number): void {
        const linkDistance = NodeLink.linkDistance(width, height);
        Node.pruneLinks(linkDistance);
        Node.generateLinks(linkDistance, this.context);
    }

    private moveNodes(): void {
        for (const node of Node.nodes) {
            node.move(this.ctor.targetDeltaTime * this.speedMultiplier);
        }
    }

    private drawNodes(): void {
        for (const node of Node.nodes) {
            node.draw();
        }
        for (const nodeLink of NodeLink.nodeLinks) {
            nodeLink.draw();
        }
    }
}
