export class INode {
    colour: [number, number, number, number];
    center: [number, number];
    squareSize: number;
    locallyCachedLinks: NodeLink[];
}

export class NodeLink {
    public static nodeLinks: NodeLink[] = [];
    public static readonly linkTime = 400;
    public static readonly maxLinksPerNode = 5;
    public static linkDistance(width: number, height: number): number {
        return Math.max(width, height) * 0.1;
    }
    public static tranColourString(r: number, g: number, b: number, a: number): string {
        a; // ignoring this
        return this.colourString(r, g, b, 0);
    }
    public static colourString(r: number, g: number, b: number, a: number): string {
        return `rgba(${r},${g},${b},${a})`;
    }

    public nodeOne: INode;
    public nodeTwo: INode;
    private context: CanvasRenderingContext2D;
    private startTime: number = Date.now();
    private endTime?: number;
    private killPromise?: Promise<void>;
    private resolveKill?: () => void;

    constructor(context: CanvasRenderingContext2D, nodeOne: INode, nodeTwo: INode) {
        this.context = context;
        this.nodeOne = nodeOne;
        this.nodeTwo = nodeTwo;
        this.startTime = Date.now();
    }

    public draw(): void {
        if (this.endTime) {
            const timeSinceEnd = Date.now() - this.endTime;
            const currentPercentage = 1 - Math.min(1, timeSinceEnd / NodeLink.linkTime);
            this.drawLine(currentPercentage);
            if (timeSinceEnd > NodeLink.linkTime) {
                this.destroy();
            }
        } else {
            const timeSinceStart = Date.now() - this.startTime;
            const currentPercentage = Math.min(1, timeSinceStart / NodeLink.linkTime);
            this.drawLine(currentPercentage);
        }
    }

    private drawLine(completionPercent: number): void {
        this.context.beginPath();
        this.context.strokeStyle = this.generateGradient(completionPercent);

        this.context.moveTo(this.nodeOne.center[0], this.nodeOne.center[1]);
        this.context.lineTo(this.nodeTwo.center[0], this.nodeTwo.center[1]);

        this.context.stroke();
        this.context.closePath();
    }

    public kill(): Promise<void> {
        if (!this.killPromise) {
            this.endTime = Date.now();
            this.killPromise = new Promise((resolve: () => void) => {
                this.resolveKill = resolve;
            });
        }
        return this.killPromise;
    }

    private generateGradient(percentage: number): CanvasGradient {
        const gradient = this.context.createLinearGradient(
            this.nodeOne.center[0],
            this.nodeOne.center[1],
            this.nodeTwo.center[0],
            this.nodeTwo.center[1]
        );
        const position1 = Math.max(0, percentage - 0.66);
        const position2 = Math.min(0.5, percentage);
        const position3 = Math.max(0.5, 1 - percentage);
        const position4 = Math.min(1, 1 - (percentage - 0.66));

        gradient.addColorStop(position1, NodeLink.colourString(...this.nodeOne.colour));
        if (percentage < 0.8) {
            gradient.addColorStop(position2, NodeLink.tranColourString(...this.nodeOne.colour));
            gradient.addColorStop(position3, NodeLink.tranColourString(...this.nodeTwo.colour));
        }
        gradient.addColorStop(position4, NodeLink.colourString(...this.nodeTwo.colour));
        return gradient;
    }

    private destroy(): void {
        const nodeOneIndex = this.nodeOne.locallyCachedLinks.indexOf(this);
        const nodeTwoIndex = this.nodeTwo.locallyCachedLinks.indexOf(this);
        const nodeLinksIndex = NodeLink.nodeLinks.indexOf(this);

        if (nodeOneIndex > -1) this.nodeOne.locallyCachedLinks.splice(nodeOneIndex, 1);
        if (nodeTwoIndex > -1) this.nodeTwo.locallyCachedLinks.splice(nodeTwoIndex, 1);
        if (nodeLinksIndex > -1) NodeLink.nodeLinks.splice(nodeLinksIndex, 1);

        delete (this as any).nodeOne;
        delete (this as any).nodeTwo;
        delete (this as any).context;

        this.resolveKill?.call(this);
    }
}
