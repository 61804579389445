<template>
    <h2 class="my-10 w-full text-center text-2xl font-bold md:my-20 md:text-2xl lg:text-left lg:text-3xl">{{ value }}</h2>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component
export default class SubHeading extends Vue {
    @Prop
    public value: string;
}
</script>

<style></style>
