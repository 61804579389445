import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box-border flex flex-col antialiased" }
const _hoisted_2 = { class: "z-10 overflow-x-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_background = _resolveComponent("background")!
  const _component_navigation = _resolveComponent("navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_component = _resolveComponent("footer-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_background, { class: "z-0" }),
    (_ctx.shouldShowNavigation)
      ? (_openBlock(), _createBlock(_component_navigation, {
          key: 0,
          class: "z-20",
          "scroll-threshold": _ctx.scrollThreshold,
          pages: _ctx.pages,
          title: _ctx.title
        }, null, 8, ["scroll-threshold", "pages", "title"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view, { "router-view": "" }, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ]),
    (_ctx.shouldShowNavigation)
      ? (_openBlock(), _createBlock(_component_footer_component, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}