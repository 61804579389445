<template>
    <div id="Footer" class="relative w-full overflow-hidden bg-slate-950 px-[4%] py-5 md:h-24">
        <div class="flex h-full flex-col content-center items-center justify-evenly space-y-3 md:flex-row md:space-y-0">
            <div class="flex h-full flex-[1_0_1%] flex-row content-center items-center justify-start">
                <img alt="Vue logo" class="w-10" src="@/assets/Logo.svg" />
                <div class="mx-2 text-center text-sm font-semibold">{{ 'LynxNode Ventures limited' }}</div>
            </div>
            <div class="flex h-full flex-[1_0_1%] flex-col items-center justify-center text-center text-sm">
                <span>All Rights Reserved</span>
                <div>
                    <span>© Copyright </span>
                    <span>LynxNode Ventures Limited</span>
                </div>
            </div>
            <div class="divide-y-1 flex h-full flex-[1_0_1%] flex-col items-center justify-center space-y-0 md:items-end">
                <router-link class="text-sm text-amber-500" to="/">Home</router-link>
                <router-link class="text-sm text-amber-500" to="/privacy-policy">Privacy Policy</router-link>
                <router-link class="text-sm text-amber-500" to="/#Contact">Contact Us</router-link>
            </div>
        </div>
        <!-- <div class="absolute text-center text-sm bottom-2 left-0 right-0">
            <span>Copyright ©</span>
            <span> 2024 </span>
            <span>LynxNode Ventures ltd.</span>
        </div> -->
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import SubHeading from './SubHeading.vue';

@Component({
    components: {
        SubHeading
    }
})
export default class FooterComponent extends Vue {}
</script>

<style></style>
