import { Bootstrapper } from './bootstrapper';
import { firebaseTask } from './bootstrap-tasks/firebase-task';
import { routerTask } from './bootstrap-tasks/router-task';
import { IServiceLocator, serviceLocator } from './service-locator';
import { setupVueTask } from './bootstrap-tasks/setup-vue-task';

export function bootstrap(): Promise<void> {
    const bootstrapper = new Bootstrapper<IServiceLocator>();

    bootstrapper
        .addTasks(new firebaseTask())
        .addTasks(new routerTask())
        .addTasks(new setupVueTask());

    return bootstrapper.process(serviceLocator);
}
