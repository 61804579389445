<template>
    <div id="Values" class="flex w-full flex-col items-center justify-start py-12">
        <div :style="containerStyle" class="ring-offset-10 mx-16 flex max-w-screen-2xl flex-wrap items-start justify-center rounded-md bg-gray-900/90 px-12 shadow-xl ring-1 ring-slate-700 ring-offset-slate-800 backdrop-blur">
            <div class="header-flex flex min-w-[20rem] flex-col content-start items-start justify-center px-[6%] text-slate-300 xl:h-full">
                <sub-heading :value="title" />
                <block class="mt-2 text-left" :value="block" />
            </div>

            <div :style="translationStyle" class="flex flex-[0_0_22rem] flex-wrap content-start items-center justify-center transition lg:flex-[0_0_44rem] lg:justify-start">
                <div :class="{ 'lg:translate-y-1/2': key % 2 > 0 }" class="ring-offset-10 relative m-4 flex h-[32rem] flex-[0_0_20rem] flex-col items-center justify-start overflow-hidden rounded-md bg-slate-900 px-4 py-12 shadow-xl ring-1 ring-slate-700 ring-offset-slate-800 transition hover:z-10 hover:contrast-[1.05]" v-for="(value, key) in values" :key="key">
                    <span v-if="value.symbol" class="material-symbols-outlined text-center text-8xl">{{ value.symbol }}</span>
                    <h4 class="my-8 text-wrap text-center text-xl font-semibold text-rose-500">{{ value.title }}</h4>
                    <div class="mx-auto w-4/5 text-center text-slate-300">
                        {{ value.block }}
                    </div>
                    <div class="absolute bottom-[5%] h-1 w-4/5 rounded-full bg-slate-700 shadow-md" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { IValue } from '@/types';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import SubHeading from './SubHeading.vue';
import Block from './Block.vue';

@Component({
    components: {
        SubHeading,
        Block
    }
})
export default class ValuesComponent extends Vue {
    private readonly padding = 2;
    private readonly sizeOfCard = 32;
    private readonly marginY = 2;

    private readonly lgMediaQuery = window.matchMedia('(min-width: 1024px)');
    private readonly xlMediaQuery = window.matchMedia('(min-width: 1280px)');
    public numberOfLines = 0;
    public contentSize = 0;

    public translationStyle = {};

    @Prop
    public title: string;

    @Prop
    public block: string;

    @Prop
    public values: IValue[];

    // TODO: revisit resize bug with values when expanding
    public get containerStyle(): any {
        const evenNumberOffset = this.values.length % 2 > 0 ? 0 : 0.5;
        let lines = 0;
        if (!this.lgMediaQuery.matches) {
            lines = this.values.length;
        } else if (this.xlMediaQuery.matches) {
            lines = Math.floor(this.values.length / this.numberOfLines) - evenNumberOffset;
        } else {
            lines = Math.ceil(this.values.length / this.numberOfLines) + evenNumberOffset;
        }

        return {
            'margin-top': `${this.sizeOfCard / 2}rem`,
            'margin-bottom': `${this.sizeOfCard / 2}rem`,
            'padding-top': `${this.padding}rem`,
            'padding-bottom': `${this.padding}rem`,
            height: `${this.contentSize + lines * (this.sizeOfCard + this.marginY) + this.padding}rem`
        };
    }

    public mounted(): void {
        this.onXLQueryUpdate();
        this.onLGQueryUpdate();
        this.lgMediaQuery.addEventListener('change', () => this.onLGQueryUpdate());
        this.xlMediaQuery.addEventListener('change', () => this.onXLQueryUpdate());
    }

    public unmounted(): void {
        this.lgMediaQuery.removeEventListener('change', () => this.onLGQueryUpdate());
        this.xlMediaQuery.removeEventListener('change', () => this.onXLQueryUpdate());
    }

    private onXLQueryUpdate(): void {
        if (this.xlMediaQuery.matches) {
            this.contentSize = 0;
            this.translationStyle = {
                transform: `translateY(${-16 - this.padding / 2}rem)`
            };
        } else {
            this.contentSize = 24;
            this.translationStyle = {};
        }
    }

    private onLGQueryUpdate(): void {
        this.numberOfLines = this.lgMediaQuery.matches ? 2 : 1;
    }
}
</script>

<style>
.header-flex {
    flex: 0 0 100%;
}
@media (min-width: 1280px) {
    .header-flex {
        flex: 0 0 calc(100% - 44rem);
    }
}
</style>
