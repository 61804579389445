<template>
    <div class="h-screen w-screen">
        <div class="absolute top-[20%] mx-6 mt-20 flex animate-fade-up flex-col items-center animate-once md:left-[10%] md:right-[10%] md:top-[44%] md:m-0 md:h-[60%]">
            <!-- <img alt="Vue logo" class="inline w-40" src="@/assets/Logo.svg" /> -->
            <h1 class="p-4 text-center text-4xl font-bold md:text-5xl">{{ subTitle }}</h1>
            <h3 class="text-md p-4 text-center text-slate-300 md:text-lg">{{ textBlock }}</h3>
            <router-link class="mx-4 w-auto rounded p-2 text-center text-slate-300 ring-1 ring-amber-500 drop-shadow-md hover:bg-amber-500 hover:text-indigo-50" :to="button.path">{{ button.locale }}</router-link>
        </div>
    </div>
</template>

<script lang="ts">
import { IButton } from '@/types';
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component
export default class HeaderComponent extends Vue {
    @Prop()
    public subTitle: string;

    @Prop()
    public textBlock: string;

    @Prop()
    public button: IButton;
}
</script>

<style></style>
