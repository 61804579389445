import { IDBHandler } from '@/types';
import { FirebaseApp } from 'firebase/app';
import { Firestore, addDoc, collection, getFirestore } from 'firebase/firestore';

export class FirestoreDBHandler implements IDBHandler {
    private firestore: Firestore;

    constructor(app: FirebaseApp) {
        this.firestore = getFirestore(app);
    }

    public sendEmail(email: any): Promise<void> {
        delete email.attachements;
        const docc = collection(this.firestore, 'PendingEmails');
        return addDoc(docc, email).then(() => void 0);
    }
}
