import { createApp } from 'vue';
import App from '@/App.vue';
import MaintenanceView from '@/views/maintenance/MaintenanceView.vue';
import { AbstractTask } from '../abstract-task';
import { routerTask } from './router-task';
import { IServiceLocator } from '../service-locator';

export class setupVueTask extends AbstractTask<IServiceLocator> {
    public process(serviceLocator: IServiceLocator): Promise<IServiceLocator> {
        if (serviceLocator.externalConfig?.maintenanceMode) {
            createApp(MaintenanceView).mount('#app');
        } else {
            createApp(App)
                .use(routerTask.router)
                .provide('externalConfig', serviceLocator.externalConfig)
                .provide('dbHandler', serviceLocator.dbHandler)
                .mount('#app');
        }

        return Promise.resolve(serviceLocator);
    }
}
