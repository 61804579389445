import { Config } from '@/firebase/remote-config/config';
import defaultConfig from '@/firebase/remote-config/default-config.json';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { RemoteConfig, fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';
import { AbstractTask } from '../abstract-task';
import { IServiceLocator } from '../service-locator';
import { FirestoreDBHandler } from '@/firebase/firestore/db-handler';

export class firebaseTask extends AbstractTask<IServiceLocator> {
    public process(serviceLocator: IServiceLocator): Promise<IServiceLocator> {
        const app = this.initFirebase();
        const remoteConfig = getRemoteConfig(app);
        serviceLocator.externalConfig = new Config(remoteConfig);
        serviceLocator.dbHandler = new FirestoreDBHandler(app);
        return this.initRemoteConfig(remoteConfig).then(() => serviceLocator);
    }

    private initFirebase(): FirebaseApp {
        const firebaseConfig = {
            apiKey: 'AIzaSyARKIQXCSnodpXM1J1trgZiBg88k2SeWj4',
            authDomain: 'lynxnode-io.firebaseapp.com',
            projectId: 'lynxnode-io',
            storageBucket: 'lynxnode-io.appspot.com',
            messagingSenderId: '418817629580',
            appId: '1:418817629580:web:3398c43bffc4fc59540aa9',
            measurementId: 'G-QVN4SY6N24'
        };

        return initializeApp(firebaseConfig);
    }

    private initRemoteConfig(remoteConfig: RemoteConfig): Promise<void> {
        if (process.env.NODE_ENV === 'development') {
            remoteConfig.settings.minimumFetchIntervalMillis = 1;
        }
        remoteConfig.defaultConfig = defaultConfig;
        return fetchAndActivate(remoteConfig)
            .then((activated: boolean) => {
                if (activated) {
                    window.console.debug('Firebase/RemoteConfig: activated new values');
                } else {
                    window.console.debug('Firebase/RemoteConfig: using cached values');
                }
            })
            .catch((err) => {
                window.console.debug('Firebase/RemoteConfig: failed to fetch config', err);
            });
    }
}
