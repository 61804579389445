/* eslint-disable prettier/prettier */
import { INode, NodeLink } from './node-link';

type squarePoints = [[number, number], [number, number], [number, number], [number, number]];

function degToRad(deg: number): number {
    return deg * (Math.PI / 180);
}

export class Node implements INode {
    public static nodes: Node[] = [];
    public static readonly alphaRange = { min: 0.5, max: 0.7 };
    public static readonly squareSize = { min: 5, max: 10 };
    public static readonly directionRange = { min: degToRad(225), max: degToRad(315) };
    public static readonly speedRange = { min: 0.08, max: 0.12 };
    public static readonly colours: [number, number, number][] = [
        [249, 115, 22],
        [202, 138, 4],
        [79, 70, 229],
        [59, 130, 246],
        [220, 38, 38]
    ];
    public static colourString(r: number, g: number, b: number, a: number): string {
        return `rgba(${r},${g},${b},${a})`;
    }

    public static pruneLinks(linkDistance: number): void {
        for (const link of NodeLink.nodeLinks) {
            const nodeOne = link.nodeOne;
            const nodeTwo = link.nodeTwo;
            const xDiff = Math.abs(nodeOne.center[0] - nodeTwo.center[0]);
            const yDiff = Math.abs(nodeOne.center[1] - nodeTwo.center[1]);

            if (xDiff > linkDistance || yDiff > linkDistance) {
                link.kill();
            }
        }
    }

    public static generateLinks(linkDistance: number, context: CanvasRenderingContext2D): void {
        for (const nodeOne of Node.nodes) {
            // eslint-disable-next-line prettier/prettier
            if (nodeOne.dirty || nodeOne.locallyCachedLinks.length >= NodeLink.maxLinksPerNode) {
                continue;
            }
            for (const nodeTwo of Node.nodes) {
                if (nodeTwo.dirty || nodeOne === nodeTwo || nodeTwo.locallyCachedLinks.length >= NodeLink.maxLinksPerNode) {
                    continue;
                }
                const xDiff = Math.abs(nodeOne.center[0] - nodeTwo.center[0]);
                const yDiff = Math.abs(nodeOne.center[1] - nodeTwo.center[1]);

                if (xDiff < linkDistance && yDiff < linkDistance) {
                    const link = new NodeLink(context, nodeOne, nodeTwo);
                    NodeLink.nodeLinks.push(link);
                    nodeTwo.locallyCachedLinks.push(link);
                    nodeOne.locallyCachedLinks.push(link);
                }
            }
        }
    }

    public colour: [number, number, number, number];
    public dirty = false;
    public squareSize = 0;
    public locallyCachedLinks: NodeLink[] = [];

    private context: CanvasRenderingContext2D;
    private startPoint: [number, number];
    private points: squarePoints;
    private oppositeManipulation = 0;
    private adjacentManipulation = 0;
    private alpha = 0;
    private speed = 0;
    private direction = 0;
    private width = 0;
    private height = 0;

    public get center(): [number, number] {
        return [
            (this.points[0][0] + this.points[2][0]) / 2,
            (this.points[0][1] + this.points[2][1]) / 2
        ];
    }

    constructor(context: CanvasRenderingContext2D, startPoint: [number, number]) {
        this.context = context;
        Node.nodes.push(this);
        this.startPoint = startPoint;
        this.generateSquareRandoms();
    }

    public move(movementMultiplier: number): void {
        for (const point of this.points) {
            const movementAmount = this.speed * movementMultiplier;
            point[0] += Math.cos(this.direction) * movementAmount;
            point[1] += Math.sin(this.direction) * movementAmount;
        }
    }

    public updateCanvasSize(width: number, height: number): void {
        this.width = width;
        this.height = height;
    }

    public checkBounds(): void {
        if (
            this.center[0] < 0 ||
            this.center[1] < 0 ||
            this.center[0] > this.width ||
            this.center[1] > this.height
        ) {
            this.dirty = true;
        }
    }

    public draw(): void {
        this.drawNode();
    }

    public clean(): void {
        Promise.all(this.locallyCachedLinks.map((link) => link.kill())).then(() => {
            this.startPoint = [Math.random() * this.width, this.height - this.squareSize];
            this.generateSquareRandoms();
            this.dirty = false;
        })
    }

    public destroy(): void {
        delete (this as any).context;
    }

    private generateSquareRandoms(): void {
        this.squareSize =
            Math.floor(Math.random() * (Node.squareSize.max - Node.squareSize.min)) +
            Node.squareSize.min;
        this.alpha =
            Math.random() * (Node.alphaRange.max - Node.alphaRange.min) + Node.alphaRange.min;
        this.direction =
            Math.random() * (Node.directionRange.max - Node.directionRange.min) +
            Node.directionRange.min;
        this.speed =
            Math.random() * (Node.speedRange.max - Node.speedRange.min) + Node.speedRange.min;
        const rgb = Node.colours[Math.floor(Math.random() * Node.colours.length)];
        this.colour = [...rgb, this.alpha];

        const angle = Math.random() * 6.3;
        this.oppositeManipulation = Math.sin(angle) * this.squareSize;
        this.adjacentManipulation = Math.cos(angle) * this.squareSize;

        this.points = [
            [this.startPoint[0], this.startPoint[1]],
            [
                this.startPoint[0] + this.oppositeManipulation,
                this.startPoint[1] + this.adjacentManipulation
            ],
            [
                this.startPoint[0] + this.adjacentManipulation + this.oppositeManipulation,
                this.startPoint[1] - this.oppositeManipulation + this.adjacentManipulation
            ],
            [
                this.startPoint[0] + this.adjacentManipulation,
                this.startPoint[1] - this.oppositeManipulation
            ]
        ];
    }

    private drawNode(): void {
        this.context.beginPath();
        this.context.moveTo(this.points[0][0], this.points[0][1]);
        const colour = Node.colourString(...this.colour);
        this.context.fillStyle = colour;
        // Due to performance reasons we can't have this.
        // this.context.shadowBlur = this.squareSize * 2;
        // this.context.shadowColor = colour;

        for (const point of this.points) {
            this.context.lineTo(point[0], point[1]);
        }

        this.context.fill();
        this.context.closePath();
    }
}
