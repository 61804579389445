import HomeView from '@/views/home/HomeView.vue';
import { Router, createRouter, createWebHistory } from 'vue-router';
import { AbstractTask } from '../abstract-task';
import { IServiceLocator } from '../service-locator';

export class routerTask extends AbstractTask<IServiceLocator> {
    public static router: Router;

    public process(serviceLocator: IServiceLocator): Promise<IServiceLocator> {
        // Moved this into task so that routes can be configured based on config loading
        routerTask.router = createRouter({
            history: createWebHistory(),
            scrollBehavior: (to) => {
                if (to.hash && to.hash !== '') {
                    return { el: to.hash };
                }
                return { top: 0, left: 0 };
            },
            routes: [
                {
                    path: '/',
                    name: 'home',
                    component: HomeView
                },
                {
                    path: '/privacy-policy',
                    name: 'privacy-policy',
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    component: () =>
                        import(
                            /* webpackChunkName: "privacy-policy" */ '@/views/privacy-policy/PrivacyPolicyView.vue'
                        )
                },
                {
                    path: '/maintenance',
                    name: 'maintenance',
                    component: () =>
                        import(
                            /* webpackChunkName: "maintenance" */ '@/views/maintenance/MaintenanceView.vue'
                        )
                }
            ]
        });

        return Promise.resolve(serviceLocator);
    }
}
