import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "About",
  class: "flex w-full flex-col items-center justify-start"
}
const _hoisted_2 = { class: "w-full max-w-screen-2xl overflow-hidden text-slate-300 lg:mx-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_heading = _resolveComponent("sub-heading")!
  const _component_quote_component = _resolveComponent("quote-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_sub_heading, {
        class: "px-[6%]",
        value: _ctx.title
      }, null, 8, ["value"]),
      _createVNode(_component_quote_component, {
        name: _ctx.founderName,
        role: _ctx.founderRole,
        quote: _ctx.founderQuote
      }, null, 8, ["name", "role", "quote"])
    ])
  ]))
}