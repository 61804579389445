<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="box-border flex flex-col antialiased">
        <background class="z-0" />
        <navigation v-if="shouldShowNavigation" class="z-20" :scroll-threshold="scrollThreshold" :pages="pages" :title="title" />
        <div class="z-10 overflow-x-hidden">
            <router-view router-view v-slot="{ Component }">
                <transition name="fade">
                    <component :is="Component" />
                </transition>
            </router-view>
        </div>
        <footer-component v-if="shouldShowNavigation" />
    </div>
</template>

<script lang="ts">
import Background from '@/components/background/Index.vue';
import { Component, Inject, Vue } from 'vue-facing-decorator';
import { IExternalConfig } from './types';
import Navigation, { IPage } from './views/navigation/Navigation.vue';
import FooterComponent from './views/home/components/FooterComponent.vue';

@Component({
    components: {
        Navigation,
        Background,
        FooterComponent
    }
})
export default class App extends Vue {
    @Inject()
    public externalConfig!: IExternalConfig;

    public scrollThreshold = window.screen.height * 0.4;
    public title: string;
    public pages: IPage[];

    public get shouldShowNavigation(): boolean {
        return this.$route.name !== 'maintenance';
    }

    public created(): void {
        this.pages = JSON.parse(this.externalConfig.navigationItems);
        this.title = this.externalConfig.title;
    }
}
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    color: theme('colors.slate.100');
    background-color: theme('colors.gray.800');
}
</style>
